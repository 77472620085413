import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faClock,
  faFolderOpen,
  faHandPointRight,
} from "@fortawesome/free-regular-svg-icons"
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS } from "@contentful/rich-text-types"
import HeroImage from "../components/heroImage"
import UseContentfulImage from "../utils/useContentfulImage"
import BackContact from "../components/backContact"

const options = {
  renderNode: {
    [BLOCKS.HEADING_2]: (node, children) => (
      <h2>
        <FontAwesomeIcon icon={faHandPointRight} />
        {children}
      </h2>
    ),
    [BLOCKS.EMBEDDED_ASSET]: node => {
      return (
        <Img
          fluid={UseContentfulImage(node.data.target.file.url)}
          alt={
            node.data.target.description
              ? node.data.target.description
              : node.data.target.title
          }
        />
      )
    },
  },
}

const BlogPost = ({ data, pageContext, location }) => {
  const title = `${data.contentfulBlogPost.title} | お知らせ・ブログ | 大阪`
  const description = `「${data.contentfulBlogPost.title}」の記事です。その内容について大阪の司法書士事務所である司法書士法人みつわ合同事務所がご紹介します。`
  return (
    <Layout>
      <SEO
        description={description}
        title={title}
        pagepath={location.pathname}
        pageimg={data.blog.childImageSharp.original.src}
        pageimgw={data.blog.childImageSharp.original.width}
        pageimgh={data.blog.childImageSharp.original.height}
      />
      <HeroImage
        name={"blog"}
        fluid={data.blog.childImageSharp.fluid}
        title={"司法書士法人みつわ合同事務所"}
        subTitle={data.contentfulBlogPost.title}
      />
      <article className="blog">
        <div className="blog__container">
          <h1 className="blog__title">{data.contentfulBlogPost.title}</h1>
          <aside className="blog__info">
            <time dateTime={data.contentfulBlogPost.createdDate}>
              <FontAwesomeIcon icon={faClock} />
              {data.contentfulBlogPost.createdDateJP}
            </time>
            <div className="blog__cat">
              <FontAwesomeIcon icon={faFolderOpen} />
              <ul>
                {data.contentfulBlogPost.category.map(cat => (
                  <li className={cat.categorySlug} key={cat.id}>
                    <Link to={`/category/${cat.categorySlug}/`}>
                      {cat.category}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </aside>
          <div className="blog__postbody">
            {renderRichText(data.contentfulBlogPost.body, options)}
          </div>
          <ul className="blog__postlink">
            {pageContext.next && (
              <li className="blog__postlink__prev">
                <Link to={`/blog/news/${pageContext.next.slug}/`} rel="prev">
                  <FontAwesomeIcon icon={faChevronLeft} />
                  <span>{pageContext.next.title}</span>
                </Link>
              </li>
            )}
            {pageContext.previous && (
              <li className="blog__postlink__next">
                <Link
                  to={`/blog/news/${pageContext.previous.slug}/`}
                  rel="next"
                >
                  <span>{pageContext.previous.title}</span>
                  <FontAwesomeIcon icon={faChevronRight} />
                </Link>
              </li>
            )}
          </ul>
        </div>
      </article>
      <BackContact link={"/blog/"} linkName={"新着記事"} />
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    contentfulBlogPost(id: { eq: $id }) {
      body {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            file {
              url
            }
            description
            title
          }
        }
      }
      title
      createdDateJP: createdDate(formatString: "YYYY年MM月DD日 HH:mm")
      createdDate
      category {
        category
        categorySlug
        id
      }
    }
    blog: file(relativePath: { eq: "meeting.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
        original {
          height
          src
          width
        }
      }
    }
  }
`

export default BlogPost
